.contact-root{

    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    max-width: 100vw;

    @media (max-width:786px) {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        max-width: 100vw;
        
    }

}

.contact-card{
    margin: 4rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding:3rem;
    background-color: pink;
    border-radius: 4rem;
    box-shadow: -0.2rem -0.2rem violet;


    @media (max-width:786px) {
        margin-top: 4rem;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding:3rem;
        background-color: pink;
        border-radius: 4rem;
        
    }


}

.contacts{
    display: flex;
    flex-direction: row;
    background-color: rgb(222, 125, 204);
    text-align: center;
    padding:2rem;
    border-radius: 2rem;
    margin-top: 2rem;
    width: 50vw ;


    @media (max-width:786px) {

        display: flex;
        flex-direction: row;
        background-color: rgb(222, 125, 204);
        padding:1rem;
        padding-left: 2rem;
        border-radius: 2rem;
        margin-top: 2rem;
        width: 60vw ;
        
    }

    
}

.contact-icon{
    max-height: 10vh;
    margin-right: 1rem;

    @media (max-width:786px) {
        max-height: 15vh;
        max-width: 10vw;
        margin-right: 1rem;
 
    }

}

.text{
    font-size: 3vw;
    color: rgb(13, 102, 27);
    text-decoration: underline;

    @media (max-width:786px) {
        font-size: 3vw;
        margin-top: 1rem;
        
    }
}