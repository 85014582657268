.video-1 {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    height: 52rem; 
    width: 30%;
    float: right;
    margin-left: 3rem; 
    box-sizing: border-box; 
    margin-right: 4rem;
  }
  
  .image-2 {
    height: 51.5rem;
    float: right;
    margin-left: 3rem; 
    margin-right: 4rem;
    animation: slide-in-left forwards;
    animation-timeline: view();
    animation-range: entry 0% cover 40%;
    margin-top: 2rem;
    max-width: 40vw;
    border-radius: 4rem;
    box-shadow: 0.2rem 0.1rem 0rem violet;
  }
  
  .image-3 {
    height: 51.5rem;
    float: right;
    margin-left: 3rem; 
    margin-right: 4rem;
    animation: slide-in-left forwards;
    animation-timeline: view();
    animation-range: entry 0% cover 40%;
    margin-top: 2rem;
    max-width: 40vw;
    border-radius: 4rem;
    box-shadow: 0.2rem 0.1rem 0rem violet;
  }
  
  .line {
    margin-bottom: 4rem; 
    font-size: 3rem;
    padding: 1rem;
    color: rgb(255, 240, 240);
    text-align: center;
    text-shadow: 0.2rem 0.1rem 0rem violet;
    background: radial-gradient(circle, rgb(232, 66, 166), rgb(245, 206, 206));
    width: 100vw;
  }
  
  .icons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  
  .icon-card-1 {
    display: flex;
    flex-direction: column;
    max-width: 25rem;
    margin: 2rem; 
    text-align: center;
    animation: slide-in-left forwards;
    animation-timeline: view(); 
    animation-range: entry 0% cover 40%;
    background-color: rgb(250, 186, 197);
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 3px 1px 0px rgb(245, 106, 227);
  }
  
  .icon-card-2 {
    display: flex;
    flex-direction: column;
    max-width: 26rem;
    margin: 2rem;
    text-align: center;
    animation: slide-in-right forwards;
    animation-timeline: view(); 
    animation-range: entry 0% cover 40%;
    background-color: rgb(250, 186, 197);
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 3px 1px 0px rgb(245, 106, 227);
  }


  .icon-title{
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 1.5rem;
    color: rgb(150, 1, 135);
    text-decoration: underline;
    opacity: 70%;
  }

  
  .icon {
    color: rgb(211, 30, 163);
    font-size: 4.5rem;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .video-1 {
      height: 30rem;
      width: 100vw;
      float: none;
      margin-left: 0;
      margin-right: 0;
    }
  
    .icon-card-1, .icon-card-2, .icon-card-static {
      max-width: 100%;
      margin: 1rem;
    }
  }
  
  @media (max-width: 576px) {
    .image-2 {
      height: auto;
      max-width: 90vw;
      float: none;
      margin-left: 5vw;
      margin-right: 0;
    }
  
    .image-3 {
      height: 80vh;
      max-width: 90vw;
      float: none;
      margin-left: 5vw;
      margin-right: 0;
    }
  
    .icons {
      flex-direction: column;
      align-items: center;
    }
  }
  