.background-3{
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: rgb(248, 205, 212);
    align-items: center;

    
}

.mask-image{
    height: 18rem;
    border-radius: 50%;
    margin-top: 4rem;
    @media (max-width:768px){
        max-width: 50vw;
        max-height: 50vw;
    }
}

.heading-3 {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 4rem;
    color: rgb(150, 1, 135);
    opacity: 30%;
  }


  .heading-4 {
    color: rgb(0, 0, 0);
    font-size: 3.5rem;
    text-decoration: underline;
    text-shadow: 4px 2px 0px rgba(233, 18, 226, 0.3);
    max-width: 90vw;
  }

  .heading-5 {
    color: rgb(0, 0, 0);
    font-size: 2rem;
    text-shadow: 4px 2px 0px rgba(233, 18, 226, 0.3);
    max-width: 90vw;
    margin-bottom: 2rem;
    text-align: center;
  }
  

.card{
    text-align: center;
    max-width: 90vw;   
    background-color: rgb(245, 182, 206);
    margin-top: 4rem;
    border-radius: 3rem;
    box-shadow: -0.3rem -0.3rem 0rem violet;
    padding: 2rem;
    margin-bottom: 3rem;

}

@media (max-width:768px){
    .heading-3{
        display: none;
    }

    .card{
        text-align: center;
        max-width: 90vw;   
        background-color: rgb(246, 175, 202);
        margin-top: 4rem;
        border-radius: 3rem;
        box-shadow: -0.3rem -0.3rem 0rem violet;
        padding: 2rem;
    
    }

    .heading-4{
        font-size: 2rem;
    }

    .heading-5{
        font-size: 1rem;
    }


}




